// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brows-desktop-brows-js": () => import("./../../../src/pages/brows/Desktop_brows.js" /* webpackChunkName: "component---src-pages-brows-desktop-brows-js" */),
  "component---src-pages-brows-mobile-brows-js": () => import("./../../../src/pages/brows/Mobile_brows.js" /* webpackChunkName: "component---src-pages-brows-mobile-brows-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lashes-desktop-lashes-js": () => import("./../../../src/pages/lashes/Desktop_lashes.js" /* webpackChunkName: "component---src-pages-lashes-desktop-lashes-js" */),
  "component---src-pages-lashes-mobile-lashes-js": () => import("./../../../src/pages/lashes/Mobile_lashes.js" /* webpackChunkName: "component---src-pages-lashes-mobile-lashes-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-services-desktop-services-js": () => import("./../../../src/pages/services/desktop_services.js" /* webpackChunkName: "component---src-pages-services-desktop-services-js" */),
  "component---src-pages-services-eyebrows-brow-policies-brow-faq-brow-prep-after-js": () => import("./../../../src/pages/services/eyebrows/brow_policies/brow_faq/brow_prep_after.js" /* webpackChunkName: "component---src-pages-services-eyebrows-brow-policies-brow-faq-brow-prep-after-js" */),
  "component---src-pages-services-eyebrows-brow-policies-brow-faq-js": () => import("./../../../src/pages/services/eyebrows/brow_policies/brow_faq.js" /* webpackChunkName: "component---src-pages-services-eyebrows-brow-policies-brow-faq-js" */),
  "component---src-pages-services-eyebrows-brow-policies-js": () => import("./../../../src/pages/services/eyebrows/brow_policies.js" /* webpackChunkName: "component---src-pages-services-eyebrows-brow-policies-js" */),
  "component---src-pages-services-eyebrows-brow-removal-policies-js": () => import("./../../../src/pages/services/eyebrows/brow_removal_policies.js" /* webpackChunkName: "component---src-pages-services-eyebrows-brow-removal-policies-js" */),
  "component---src-pages-services-eyebrows-js": () => import("./../../../src/pages/services/eyebrows.js" /* webpackChunkName: "component---src-pages-services-eyebrows-js" */),
  "component---src-pages-services-eyebrows-touch-up-policies-js": () => import("./../../../src/pages/services/eyebrows/touch_up_policies.js" /* webpackChunkName: "component---src-pages-services-eyebrows-touch-up-policies-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-lashes-js": () => import("./../../../src/pages/services/lashes.js" /* webpackChunkName: "component---src-pages-services-lashes-js" */),
  "component---src-pages-services-lashes-lashes-policies-js": () => import("./../../../src/pages/services/lashes/lashes_policies.js" /* webpackChunkName: "component---src-pages-services-lashes-lashes-policies-js" */),
  "component---src-pages-services-lashes-lashes-policies-lashes-prep-after-js": () => import("./../../../src/pages/services/lashes/lashes_policies/lashes_prep_after.js" /* webpackChunkName: "component---src-pages-services-lashes-lashes-policies-lashes-prep-after-js" */),
  "component---src-pages-services-mobile-services-js": () => import("./../../../src/pages/services/mobile_services.js" /* webpackChunkName: "component---src-pages-services-mobile-services-js" */),
  "component---src-pages-services-tattoo-removal-js": () => import("./../../../src/pages/services/tattoo_removal.js" /* webpackChunkName: "component---src-pages-services-tattoo-removal-js" */),
  "component---src-pages-services-tattoo-removal-tattoo-policies-js": () => import("./../../../src/pages/services/tattoo_removal/tattoo_policies.js" /* webpackChunkName: "component---src-pages-services-tattoo-removal-tattoo-policies-js" */),
  "component---src-pages-services-waxing-js": () => import("./../../../src/pages/services/waxing.js" /* webpackChunkName: "component---src-pages-services-waxing-js" */),
  "component---src-pages-services-waxing-lashes-policies-js": () => import("./../../../src/pages/services/waxing/lashes_policies.js" /* webpackChunkName: "component---src-pages-services-waxing-lashes-policies-js" */),
  "component---src-pages-services-waxing-lashes-policies-lashes-prep-after-js": () => import("./../../../src/pages/services/waxing/lashes_policies/lashes_prep_after.js" /* webpackChunkName: "component---src-pages-services-waxing-lashes-policies-lashes-prep-after-js" */),
  "component---src-pages-tattoo-removal-desktop-tattoo-js": () => import("./../../../src/pages/tattoo_removal/Desktop_tattoo.js" /* webpackChunkName: "component---src-pages-tattoo-removal-desktop-tattoo-js" */),
  "component---src-pages-tattoo-removal-mobile-tattoo-js": () => import("./../../../src/pages/tattoo_removal/Mobile_tattoo.js" /* webpackChunkName: "component---src-pages-tattoo-removal-mobile-tattoo-js" */),
  "component---src-pages-waxing-desktop-waxing-js": () => import("./../../../src/pages/waxing/Desktop_waxing.js" /* webpackChunkName: "component---src-pages-waxing-desktop-waxing-js" */),
  "component---src-pages-waxing-mobile-waxing-js": () => import("./../../../src/pages/waxing/Mobile_waxing.js" /* webpackChunkName: "component---src-pages-waxing-mobile-waxing-js" */)
}

